import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClaimedUserNFT, UserNFT } from '../../../shared/services/genesis/genesis.interfaces';

// For html template
interface MixedNftData extends ClaimedUserNFT, UserNFT {
}

@Component({
  selector: 'app-genesis-nft-card',
  templateUrl: './genesis-nft-card.component.html',
  styleUrls: ['./genesis-nft-card.component.scss']
})
export class GenesisNftCardComponent {
  @Input() nft!: Partial<MixedNftData>;

  @Input() isSelected: boolean = false;
  @Output() isSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleSelected() {
    this.isSelected = !this.isSelected;
    this.isSelectedChange.emit(this.isSelected);
  }

  totalRewardCalculation(coinsCount: number, rewardPerCoin: number, startDate: string = '2024-03-26'): number {
    const today = new Date();
    const start = new Date(startDate);
    const diffInMonths = (today.getFullYear() - start.getFullYear()) * 12 + (today.getMonth() - start.getMonth());
    const daysPassed = today.getDate() - start.getDate();
    const adjustedMonths = daysPassed >= 26 ? diffInMonths + 1 : diffInMonths;
    // Calculate the total reward (coins count * reward per coin * adjusted months)
    return coinsCount * rewardPerCoin * adjustedMonths;
  }

}
