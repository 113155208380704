// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-banner {
  background: url('background.png') no-repeat top center;
  background-size: cover;
}
@media (max-width: 991px) {
  .main-banner {
    background-size: auto;
  }
}
.main-banner__wrapper {
  padding: 150px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .main-banner__wrapper {
    padding: 60px 0;
    flex-direction: column;
    text-align: center;
  }
}
.main-banner__item {
  width: 45%;
}
@media (max-width: 991px) {
  .main-banner__item {
    width: 100%;
    margin: 10px 0;
  }
}
.main-banner__item-from-left {
  animation: 1s ease-out 0s 1 slideInLeft;
}
.main-banner__item-header {
  margin: 20px 0;
  color: var(--color-text);
  font-size: 30px;
  line-height: 42px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .main-banner__item-header {
    font-size: 20px;
    line-height: 30px;
  }
}
.main-banner__item-text {
  margin: 0 0 20px 0;
  color: var(--color-text);
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
}
@media (max-width: 991px) {
  .main-banner__item-text {
    font-size: 14px;
    line-height: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/gaimin-testers/sections/testers-banner/testers-banner.component.scss"],"names":[],"mappings":"AAAA;EACE,sDAAA;EACA,sBAAA;AACF;AAAE;EAHF;IAII,qBAAA;EAGF;AACF;AADE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAGJ;AAFI;EALF;IAMI,eAAA;IACA,sBAAA;IACA,kBAAA;EAKJ;AACF;AAFE;EACE,UAAA;AAIJ;AAHI;EAFF;IAGI,WAAA;IACA,cAAA;EAMJ;AACF;AAJI;EACE,uCAAA;AAMN;AAHI;EACE,cAAA;EACA,wBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAKN;AAJM;EANF;IAOI,eAAA;IACA,iBAAA;EAON;AACF;AAJI;EACE,kBAAA;EACA,wBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAMN;AALM;EANF;IAOI,eAAA;IACA,iBAAA;EAQN;AACF","sourcesContent":[".main-banner {\n  background: url('~src/assets/background.png') no-repeat top center;\n  background-size: cover;\n  @media (max-width: 991px) {\n    background-size: auto;\n  }\n\n  &__wrapper {\n    padding: 150px 0;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    @media (max-width: 991px) {\n      padding: 60px 0;\n      flex-direction: column;\n      text-align: center;\n    }\n  }\n\n  &__item {\n    width: 45%;\n    @media (max-width: 991px) {\n      width: 100%;\n      margin: 10px 0;\n    }\n\n    &-from-left {\n      animation: 1s ease-out 0s 1 slideInLeft;\n    }\n\n    &-header {\n      margin: 20px 0;\n      color: var(--color-text);\n      font-size: 30px;\n      line-height: 42px;\n      font-weight: 500;\n      @media (max-width: 991px) {\n        font-size: 20px;\n        line-height: 30px;\n      }\n    }\n\n    &-text {\n      margin: 0 0 20px 0;\n      color: var(--color-text);\n      font-weight: 300;\n      font-size: 16px;\n      line-height: 30px;\n      @media (max-width: 991px) {\n        font-size: 14px;\n        line-height: 22px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
